import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProgramacionService {
  constructor(private http: HttpClient) {}

  getFechas() {
    return this.http.get(
      "https://simisae.com.mx/assets/data/programacion.json"
    );
  }
}
