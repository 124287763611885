<section class="section-title" style="background:url(../assets/images/programacion-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> Simichat </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a> Simichat</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col-md-9">

            <iframe width="100%" height="100%"
                src="https://www.youtube.com/embed/watch?v=6QbDJU2-qAY&list=UUmvExLVJA7dCSeurwVJN1CA&loop=1"
                                       
                
                frameborder="0" allowfullscreen=""></iframe>


        </div>
        <div class="col-md-3 programacion">
            <h3 class="programacion-titulo">
                Este mes en <span>simichat</span>
            </h3>
            <div class="programacion-list">
                <ul>
                    <li *ngFor="let programa of programas">
                        <b> {{ programa.fecha}} </b><br>
                        {{ programa.titulo }} <span> {{ programa.presentadores }}</span>
                    </li>


                </ul>

            </div>
        </div>

    </div>
</div>
<section style="background: #F5F8FA">
    <div class="container pb-5 pt-5">
        <div class="row pb-5">

            <div *ngFor="let video of videos | paginate: {itemsPerPage: 16, currentPage: pageActual}"
                class="col-md-3 col-sm-6 col-6">
                <a [href]="sanitize(video.url)" target="_blank" class="programas-item">
                    <div class="programas-item-img">
                        <img [src]="'https://i.ytimg.com/vi/' + video.thumb +'/hqdefault.jpg'" alt="">
                        <img class="ico-play" src="assets/images/ico-play.svg">
                    </div>
                    <h4 class="titulo-3 pt-1">{{ video.nombre }}</h4>
                    <p>{{ video.cuando }}</p>
                </a>
            </div>
            <div class="col-12 text-center" style="display: flex;">
                <pagination-controls (pageChange)="pageActual = $event" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true"  style="margin: auto;">
                </pagination-controls>
            </div>

        </div>
    </div>
</section>