<div class="container container-full">
    <div class="row">
        <div class="col-10 mx-auto text-center pt-5">
             <div class="col-12 text-left mb-3">
                <h2>Comentarios <button class="btn btn-primary"  style="float:right" routerLink="/administrador/gestor" >
                    <span > ❮ Regresar</span>
                </button></h2>
            </div>
            <div class="table-responsive"> 
                <table class="table">
                    <thead class="thead">
                        <tr>
                            <th>Nota</th>
                            <th>Calificación</th>
                            <th>Comentario</th>
                            <th>Correo</th>
                            <th>Fecha</th>
                            
                        </tr>
                    </thead>
                    <tbody >
                    
                        <tr class="hover" *ngFor="let test of tests  | paginate: {itemsPerPage: 10, currentPage: pageActual}">
                            <td >{{test.nombreNota}}</td>
                            <td >{{test.calificacion}}</td>
                            <td >{{test.mensaje}}</td>
                            <td >{{test.correo}}</td>
                            <td >{{test.fechaVoto |  date:'short'}}</td>
                        </tr>
                        
                    </tbody>
                </table>
                <div class="col-12 text-center" style="display: flex;">
                            <pagination-controls (pageChange)="pageActual = $event" previousLabel="Anterior" nextLabel="Siguiente" [responsive]="true" style="margin: auto;"> </pagination-controls>
                        </div>
            </div>
        </div>
    </div>
</div>
