import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StreamingService {
  constructor(private http: HttpClient) {}

	getStreaming() {
		const date = new Date()
		const day = date.getDay()
		const hours = date.getHours()

		if (day === 2 || day === 4) {
			if (hours === 18) {
				return true
			}
		}

		return false
	}

	getVideos() {
		return this.http.get(
			'assets/data/videos.json'
		)
	}

	enviarContacto(formulario: any) {
		return this.http
		.post(`https://farmaciasdesimilares.com/simicomentarios/api/Comentarios`, formulario, {
		  headers: {
			'Content-Type':'application/json',
			'Accept': 'application/json'
		  }
		});
	}

	postNewSubscriber(formulario: any) {
		return this.http.post(
			'https://simisae.com.mx/newsletter/api/subscriptores',
			formulario
		)
	}
}
