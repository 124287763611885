import { Component, OnInit } from '@angular/core';
import { DomSanitizer, Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { StreamingService } from "../../services/streaming.service";
import { ProgramacionService } from "../../services/programacion.service";


@Component({
  selector: 'app-programacion',
  templateUrl: './programacion.component.html',
  styleUrls: ['./programacion.component.css']
})
export class ProgramacionComponent implements OnInit {
  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;
  videos!: any;
  programas!: any;
  loading = true;

  constructor(
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private programacionService: ProgramacionService,
    private streamingService: StreamingService,
    private _seoService: SeoService,
    private sanitizer: DomSanitizer,
    public meta: Meta,
    public title: Title
  ) {
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
    });

    this.loading = true;

    this.streamingService.getVideos().subscribe((resp: any) => {
      this.videos = resp.reverse();
    });

    this.programacionService.getFechas().subscribe((respdos: any) => {
      this.programas = respdos;
    });

  }

  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url',content: 'https://simisae.com.mx/'})
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}


  ngOnInit() {}

  pageActual: number = 1;

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
