import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JsonLdService {
  	static scriptType = 'application/ld+json';
		
	constructor(@Inject(DOCUMENT) private _document: Document) {}

	removeStructuredData(): void {
		const els: Element[] = [];
		[ 'structured-data', 'structured-data-org' ].forEach(c => {
			els.push(...Array.from(this._document.head.getElementsByClassName(c)));
		});
		els.forEach(el => this._document.head.removeChild(el));
	}

	insertSchema(schema: Record<string, any>, className = 'structured-data'): void {
		let script: any;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonLdService.scriptType;

		let schemapro = {
			"@context": "https://schema.org",
			"@type": "Article",
			"mainEntityOfPage": {
			  "@type": "WebPage",
			  "@id": schema[0].id
			},
		  "headline": schema[0].headline,
			"description": schema[0].description,
			"image": schema[0].image,  
			"author": {
			  "@type": "Organization",
			  "name": "Fundación BEST"
			},
			"publisher": {
			  "@type": "Organization",
			  "name": "Fundación BEST",
			  "logo": {
				"@type": "ImageObject",
				"url": "https://fundacionbest.org.mx/assets/images/logo-gde.jpg",
				"width": 600,
				"height": 60
			  }
			},
			"datePublished": schema[0].datePublished,
			"dateModified": schema[0].dateModified
		  }
		
		
		script.text = JSON.stringify(schemapro);
		
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}

	insertSchemaindex(schema: Record<string, any>, className = 'structured-data'): void {
		let script: any;
		let shouldAppend = false;
		if (this._document.head.getElementsByClassName(className).length) {
			script = this._document.head.getElementsByClassName(className)[0];
		} else {
			script = this._document.createElement('script');
			shouldAppend = true;
		}
		script.setAttribute('class', className);
		script.type = JsonLdService.scriptType;

		let schemapro = {
			"@context": "https://schema.org",
			"@type": "Article",
			"mainEntityOfPage": {
			  "@type": "WebPage",
			  "@id": schema[0].id
			},
		  "headline": schema[0].headline,
			"description": schema[0].description,
			"image": schema[0].image,  
			"author": {
			  "@type": "Organization",
			  "name": "Fundación BEST"
			},
			"publisher": {
			  "@type": "Organization",
			  "name": "Fundación BEST",
			  "logo": {
				"@type": "ImageObject",
				"url": "https://fundacionbest.org.mx/assets/images/logo-gde.jpg",
				"width": 600,
				"height": 60
			  }
			},
			"datePublished": schema[0].datePublished,
			"dateModified": schema[0].dateModified
		  }
		
		
		script.text = JSON.stringify(schemapro);
		
		if (shouldAppend) {
			this._document.head.appendChild(script);
		}
	}
}
