import { Component, OnInit } from '@angular/core';
import { StreamingService } from '../../services/streaming.service'
import { NgForm } from '@angular/forms'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private streaminService: StreamingService) {
  }
  year: any;
  Oksub: boolean | undefined;
  Okerr: boolean | undefined;
  ngOnInit() {
    this.year = new Date().getFullYear();
  }

  enviarSuscripcion(form: NgForm) {
    this.streaminService.postNewSubscriber(form.value).subscribe(
      (resp: any) => {
        form.reset();
        this.Oksub = true;

        setTimeout(() => {
          this.Oksub = false;
        }, 2500);
      },
      error =>{ console.log(error.statusText)
        form.reset();
        this.Okerr = true;

        setTimeout(() => {
          this.Okerr = false;
        }, 2500);
      }
      

    )
  }
}
