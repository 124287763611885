<section class="section-title" style="background:url(../assets/images/test-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> Test </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a> Test</p>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="container">
    <div class="row">
        <div class="col-12 mt-5 text-center mt-xl mb-4">
            <h2 class="titulo-1 pb-4">{{ testName }} <a class="btn-mas" (click)="isCollapsed = !isCollapsed"
                    [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic"><img
                        src="assets/images/ico-mas.svg" width="26" alt="">
                </a></h2>
            <div id="collapseBasic" [collapse]="isCollapsed" [isAnimated]="true">
                <div class="row well well-lg  card-header mb-5">
                    <div class="col-lg-8 offset-lg-2 pb-3 pt-3">
                        <p class="test-descripcion">{{ testDescripcion }}</p>
                    </div>
                </div>
            </div>
            <p>
                El siguiente cuestionario pretende evaluar la posibilidad de que
                presentes este trastorno. CONTESTA SI O NO.
            </p>
        </div>

        <div class="col-12 mb-5 pb-5">
            <form ngNativeValidate (ngSubmit)="getResult(testForm)" #testForm="ngForm">
                <div class="form-row form-test  pt-3 pb-0">
                    <div *ngFor="let question of questions; let i = index" class="col-md-6 pregunta">
                        <div *ngIf="!isSecondary; else secondary" class="form-group">
                            <p><span>{{i+1}} </span> {{question.question}}:</p>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" ngModel [name]="question.question" value="si">Si
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label class="form-check-label">
                                    <input type="radio" class="form-check-input" ngModel [name]="question.question" value="no">No
                                </label>
                            </div>
                        </div>
                        
                        <ng-template #secondary>
                            <div class="form-group">
                                <p><span>{{i+1}} </span> {{question.question}}:</p>
                                <div *ngFor="let label of question.labels" class="form-check-inline">
                                    <label class="form-check-label">
                                        <input type="radio" class="form-check-input" ngModel [name]="question.question"
                                            [value]="label.value +'-'+ label.label">{{label.label}}
                                    </label>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="mensaje">Explícanos tu caso. Trata de incluir los datos que consideres importantes
                            para obtener una valoración más completa.</label>
                        <textarea [(ngModel)]="comentarios" name="comentarios" class="form-control" id="mensaje"
                            rows="3" required></textarea>
                    </div>

                    <div *ngIf="unrespondedQuestions.length > 0" class="col-12">
                        <div class="alert alert-warning p-2" role="alert">
                            Hay una o varias preguntas sin responder: {{ unrespondedQuestions }}
                        </div>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-submit">Evaluar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<div *ngIf="isModalShown" [config]="{ show: true }" (onHidden)="onHidden()" bsModal #autoShownModal="bs-modal"
    class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-auto-name">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header text-center">
                <button type="button" class="close pull-right" aria-label="Close" (click)="onClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">ENVÍA TUS DATOS</h4>
                <p> Nuestros psicólogos expertos te darán respuesta en un lapso de 12 a 24 hrs</p>
            </div>
            <div class="modal-body">
                <form *ngIf="!success; else result" ngNativeValidate (ngSubmit)="sendAndShowEval()">
                    <div class="form-row">
                        <div ngNativeValidate class="form-group col-md-6">
                            <label for="inputNombre">Nombre</label>
                            <input [(ngModel)]="nombre" name="nombre" type="text" class="form-control" id="inputNombre"
                                required />
                        </div>
                        <div class="form-group col-md-6">
                            <label for="inputEmail4">Email</label>
                            <input [(ngModel)]="correo" name="correo" type="email" class="form-control" id="inputEmail4"
                                required />
                        </div>
                        <div class="form-check col-12" style="margin-left: 20px">
                            <input name="acepto" ngModel class="form-check-input" type="checkbox" id="checkAcepto"
                                required>
                            <label class="form-check-label" for="checkAcepto">
                                Acepto y estoy de acuerdo con el aviso legal
                            </label>
                        </div>
                        <div class="col-12">
                            <button class="btn btn-primary" type="submit" [disabled]="loading">
                                <span *ngIf="loading; else span" class="spinner-border spinner-border-sm" role="status"
                                    aria-hidden="true"></span>
                                <ng-template #span>
                                    <span>Resultado</span>
                                </ng-template>
                            </button>
                        </div>
                    </div>
                </form>
                <ng-template #result>
                    <h1>Resultado: </h1>
                    {{ response }}
                </ng-template>
            </div>
        </div>
    </div>
</div>