import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { InformateService } from "src/app/services/informate.service";


@Component({
  selector: 'app-informate',
  templateUrl: './informate.component.html',
  styleUrls: ['./informate.component.css']
})
export class InformateComponent implements OnInit {

  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;
  pageActual: number = 1;
  newstr: any;
  notas!: any;
  categorias!: any;

  constructor(
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private _seoService: SeoService,
    public meta: Meta,
    private informateService: InformateService,
    public title: Title
  ) {
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
    });
  }

  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url',content: 'https://simisae.com.mx/'})
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}



  ngOnInit() {
    this.informateService.getNotas().subscribe((resp: any) => {
      this.notas = resp.reverse();

      this.categorias = this.notas
        .map((item: { categoria: any; }) => item.categoria)
        .filter((value: any, index: any, self: string | any[]) => self.indexOf(value) === index);
    });
  }

  filtrarCategorias(categoria: any) {
    this.informateService.getNotas().subscribe((resp: any) => {
      if (categoria === "Todas las categorías") {
        return (this.notas = resp.reverse());
      }
      this.notas = resp.filter((nota: { categoria: string; }) => nota.categoria == categoria);
    });
  }

  getNotaUrl(nota: any) {
    this.newstr = nota.titulo.replace(/\s+/g, "-").toLowerCase();
    const removeAccents = (str: string) => {return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");} 
		this.newstr = removeAccents( this.newstr);
		var first = this.newstr.charAt(0);
		var last = this.newstr.charAt(this.newstr.length - 1)
		var r = /^[A-Z]+$/i;
    if( !r.test(first) ){
			this.newstr = this.newstr.slice(1);
		}
    if( !r.test(last) ){
      this.newstr = this.newstr.slice(0, -1);
    }
		this.newstr = this.newstr.replace(/[^a-zA-Z 0-9.]+/g,'-');
    this.router.navigate(["/articulo/" + nota.id + "/" + this.newstr]);
  }
}
