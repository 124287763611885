import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { NgForm } from '@angular/forms'
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { InformateService } from '../../services/informate.service'
import { JsonLdService } from '../../services/json-ld.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';
import { SimisaeService } from "src/app/services/simisae.service";

@Component({
    selector: 'app-articulo',
    templateUrl: './articulo.component.html',
    styleUrls: ['./articulo.component.css']
})
export class ArticuloComponent implements OnInit {
    nota!: any;
    notas!: any[];
    calificacion: any
    success: boolean | undefined
    loading: boolean | undefined
	idnotshow: any;
	newstr: any;
	tituloseo: any;
	descripcionseo: any;
	urlcanonicaseo: any;
	imagenseo: any;
	calificacionSend: any;


    constructor(
		private _jsonLdService: JsonLdService,
		private _urlcanonicaservice: UrlcanonicaService,
		private activatedRoute: ActivatedRoute,
		private _simiSAE: SimisaeService,
		private router: Router,
		private meta: Meta,
		private title: Title,
        private informateService: InformateService,
    ) {
		this.activatedRoute.params.subscribe(param => {
			this.informateService.getNotas().subscribe((resp: any) => {
				this.idnotshow = param.id
				this.nota = resp.find((nota: any) => nota.id === parseInt(param.id))
	  
				
				this._urlcanonicaservice.createCanonicalLink();
				this._jsonLdService.insertSchema(this.nota.schema);
				this.title.setTitle( `${this.nota.seo.title}`);
				this.meta.updateTag({ name: 'robots', content: 'index, follow' });
				this.meta.updateTag({ name: 'description',content: ` ${this.nota.seo.description}`});
				this.meta.updateTag({ property: 'og:url',content: `${this.nota.seo.urlcanonica}`});
				this.meta.updateTag({ property: 'og:title',content: `${this.nota.seo.title}`});
				this.meta.updateTag({ property: 'og:image',content: `${this.nota.seo.image}`});
				this.meta.updateTag({ property: 'og:description',content: ` ${this.nota.seo.description}`});
				this.meta.updateTag({ property: 'twitter:card',content: 'summary'});
				this.meta.updateTag({ property: 'twitter:site',content: '@simisaemx'});
				this.meta.updateTag({ property: 'twitter:title',content: `${this.nota.seo.title}`} );
				this.meta.updateTag({ property: 'twitter:description',content: `${this.nota.seo.description}`});
				this.meta.updateTag({ property: 'twitter:image',content: `${this.nota.seo.image}`} );
	  
	  
	  
				if(!this.nota){
				  this.router.navigate(['informate']);
				}
			})
			
			this.informateService.getNotas().subscribe((respdos: any) => {
			
			  respdos.reverse()
			  var removeIndex = respdos.map(function(item: { id: any; }) { return item.id; }).indexOf(parseInt(this.idnotshow));
			  respdos.splice(removeIndex, 1);
			  this.notas = respdos
			})
		})
	}

    ngOnInit() {
	}
	  
	getNotaUrl(nota: any) {
		this.newstr = nota.titulo.replace(/\s+/g, '-').toLowerCase();
		const removeAccents = (str: string) => {return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");} 
		this.newstr = removeAccents( this.newstr);
		var first = this.newstr.charAt(0);
		var last = this.newstr.charAt(this.newstr.length - 1)
		var r = /^[A-Z]+$/i;
		if( !r.test(first) ){
			this.newstr = this.newstr.slice(1);
		}
		if( !r.test(last) ){
		this.newstr = this.newstr.slice(0, -1);
		}
		this.newstr = this.newstr.replace(/[^a-zA-Z 0-9.]+/g,'-');
		this.router.navigate(['/articulo/' + nota.id + '/' + this.newstr]);
		this.getNota(nota.id);
		}
	  
	getNota(id: number){
		
		this.activatedRoute.params.subscribe(param => {
			this.informateService.getNotas().subscribe((resp: any) => {
				this.idnotshow = param.id
				this.nota = resp.find((nota: any) => nota.id === parseInt(param.id))

			})
			this.informateService.getNotas().subscribe((respdos: any) => {
				respdos.reverse()
				var removeIndex = respdos.map(function(item: { id: any; }) { return item.id; }).indexOf(parseInt(this.idnotshow));
				respdos.splice(removeIndex, 1);
				this.notas = respdos
			})
		})
	}
	  
	addComentario(f: NgForm) {
		this.loading = true
		console.log()
		if(f.value.email.length == 0){
			this.calificacionSend = {
				NombreNota: this.nota.titulo,
				Calificacion: this.calificacion,
				Mensaje: f.value.mensaje
			}
		}else{
			this.calificacionSend = {
				NombreNota: this.nota.titulo,
				Calificacion: this.calificacion,
				Mensaje: f.value.mensaje,
				Correo: f.value.email
			}
		}
		
		this._simiSAE.postvoto(this.calificacionSend).subscribe((resp: any) => {
			if(resp.ok == false){
			  this.success = true
			  this.loading = false  
			}else{
			  this.success = true
			  this.loading = false  
			}
		   
		});
    console.log(this.calificacionSend)

	}
}
	  