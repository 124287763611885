import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UrlcanonicaService {

  constructor(@Inject(DOCUMENT) private doc:any) {}

  createCanonicalLink() { 
    let links = this.doc.getElementsByTagName("link");
    links[1].setAttribute('href',this.doc.URL);
  }
 
  // constructor(@Inject(DOCUMENT) private dom:any) { }

  // createCanonicalLink(url?: string) {
  //   let canURL = url == undefined ? this.dom.URL : url;
  //   let link: HTMLLinkElement = this.dom.createElement('link');
  //   link.setAttribute('rel', 'canonical');
  //   this.dom.head.appendChild(link);
  //   link.setAttribute('href', canURL);
  // }

}
  
