<section class="section-title" style="background:url(../assets/images/consejos-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page text-white"><h1> ¡10 consejos del Dr. Simi para ser feliz! </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs ">
                    <p class="text-white"><a routerLink="/" class="text-white">Inicio &nbsp;/ &nbsp;</a> Consejos del
                        Dr. Simi</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container">
    <div class="row mb-5">
        <div class="col-12 text-center mt-xl pb-5">
            <h2 class="titulo-1">Empieza una nueva vida</h2>
            <h3 class="subtitle-1 pb-3">La felicidad se percibe como algo inalcanzable, como un sueño y esto no es así.
            </h3>
            <p>La felicidad es la satisfacción que tengas de tu propia vida, logros, metas, amores, encuentros y deseos.
                La felicidad también consiste en integrar tu pasado desde un punto de vista diferente, no desde lo que
                te faltó, sino de aquello que ha enriquecido tu vida y que te sigue manteniendo con metas constantes.
            </p>
        </div>
        <div class="col-lg-6 consejos-item azul">
            <div class="row">
                <div class="col-2 text-right consejos-number">1</div>
                <div class="col-10 consejos-text">
                    <h2>Verdad</h2>
                    <div class="pleca"></div>
                    <p>Decir siempre la verdad a otros y a sí mismo es básico para una buena salud emocional. Como dijo
                        Jesús: "La verdad os hará libres".</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item">
            <div class="row">
                <div class="col-2 text-right consejos-number">2</div>
                <div class="col-10 consejos-text">
                    <h2>Metas</h2>
                    <div class="pleca"></div>
                    <p>Tener ilusiones y metas positivas, económicas, intelectuales, emocionales o de cualquier índole,
                        ayuda mucho a que nuestra mente esté sanamente ocupada.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item">
            <div class="row">
                <div class="col-2 text-right consejos-number">3</div>
                <div class="col-10 consejos-text">
                    <h2>Aceptación</h2>
                    <div class="pleca"></div>
                    <p>Aceptar las cosas, buenas o malas, que nos pasan en la vida, pensando y llegando a sentir que
                        "Dios dispone" y que lo que viene conviene, ayuda mucho a nuestra tranquilidad emocional.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item azul">
            <div class="row">
                <div class="col-2 text-right consejos-number">4</div>
                <div class="col-10 consejos-text">
                    <h2>Armonía </h2>
                    <div class="pleca"></div>
                    <p>Debido a que los seres humanos estamos COMPUESTOS de MATERIA y ESPÍRITU, es fundamental tratar de
                        lograr armonía entre ambas parte, así como los demás.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item">
            <div class="row">
                <div class="col-2 text-right consejos-number">5</div>
                <div class="col-10 consejos-text">
                    <h2>Crecimiento</h2>
                    <div class="pleca"></div>
                    <p>Crecer material, intelectual y emocionalmente nos hace sentir una gran satisfacción y, para
                        lograrlo, ayuda acercamos a las personas que nos hacen crecer y alejarnos de las que nos hacen
                        decrecer; si no se puede físicamente, por lo menos mentalmente.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item">
            <div class="row">
                <div class="col-2 text-right consejos-number">6</div>
                <div class="col-10 consejos-text">
                    <h2>Perdón</h2>
                    <div class="pleca"></div>
                    <p>El perdón libera de sentimientos negativos; trae paz al corazón y genera alegría. Perdonar y
                        perdonarse es virtud de quien triunfa sobre sus propias debilidades.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item azul">
            <div class="row">
                <div class="col-2 text-right consejos-number">7</div>
                <div class="col-10 consejos-text">
                    <h2>Servicio</h2>
                    <div class="pleca"></div>
                    <p>Ayudar a los demás da mucha felicidad. Se puede dar lo que se tenga: tiempo, dinero, compañía,
                        alegría, etc. Es más feliz quien más da. Como se dice: "El que no vive para servir, no sirve
                        para vivir".</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item">
            <div class="row">
                <div class="col-2 text-right consejos-number">8</div>
                <div class="col-10 consejos-text">
                    <h2>Individualidad</h2>
                    <div class="pleca"></div>
                    <p>Aceptar que sólo somos dueños de nuestra vida, no de la de los demás. A nuestros seres queridos y
                        amigos les podemos ayudar y aconsejar; pero respetando su individualidad.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item">
            <div class="row">
                <div class="col-2 text-right consejos-number">9</div>
                <div class="col-10 consejos-text">
                    <h2>Libertad</h2>
                    <div class="pleca"></div>
                    <p>luchar para conseguir depender lo menos posible de personas, cosas o hechos, nos permitirá
                        sentirnos libres.</p>
                </div>
            </div>
        </div>
        <div class="col-lg-6 consejos-item azul">
            <div class="row">
                <div class="col-2 text-right consejos-number">10</div>
                <div class="col-10 consejos-text">
                    <h2>Dios </h2>
                    <div class="pleca"></div>
                    <p>Gran ayuda emocional proporciona creer siempre en un ser superior presente en todos los actos de
                        nuestra vida, porque nos ayuda a aceptar nuestro destino.</p>
                </div>
            </div>
        </div>
    </div>
</div>