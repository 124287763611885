import { Component, OnInit } from '@angular/core';
import { Meta, Title } from "@angular/platform-browser";
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SeoService } from '../../services/seo.service';
import { UrlcanonicaService } from '../../services/urlcanonica.service';


@Component({
  selector: 'app-aviso-legal',
  templateUrl: './aviso-legal.component.html',
  styleUrls: ['./aviso-legal.component.css']
})
export class AvisoLegalComponent implements OnInit {

  RouteText : any;
  tituloseo: any;
  urlcanonicaseo: any;
  imagenseo: any;
  keywordsseo: any;
  descripcionseo: any;


  constructor(
    private router: Router,
    private _urlcanonicaservice: UrlcanonicaService,
    private _seoService: SeoService,
    public meta: Meta,
    public title: Title
  ) {
    this.router.events.pipe(filter((event: any) =>  event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
			this.RouteText = event;
			this.getSEO(event.url)
    });
  }

  getSEO(page: string){
		this._seoService.getSEO(page).subscribe((resp: any) => {
			this.tituloseo = resp.titulo;
			this.descripcionseo = resp.descripcion;
			this.urlcanonicaseo =  resp.urlcanonica;
			this.imagenseo = resp.image; 

			this._urlcanonicaservice.createCanonicalLink();
			this.title.setTitle(this.tituloseo)
			this.meta.updateTag({ name: 'description', content: this.descripcionseo })

			this.meta.updateTag({ property: 'twitter:card', content: 'summary' })
			this.meta.updateTag({ property: 'twitter:site', content: '@simisaemx' })
			this.meta.updateTag({ property: 'twitter:title', content: 'SIMISAE' })
			this.meta.updateTag({ property: 'twitter:description', content: this.descripcionseo })
			this.meta.updateTag({ property: 'twitter:image', content: this.imagenseo })
			this.meta.updateTag({ property: 'og:url',content: 'https://simisae.com.mx/'})
			this.meta.updateTag({ property: 'og:title', content: this.tituloseo })
			this.meta.updateTag({ property: 'og:image', content: this.imagenseo})
			this.meta.updateTag({ property: 'og:description', content: this.descripcionseo})       
		});
	}


  ngOnInit() {
  }

}
