<section class="section-title" style="background:url(../assets/images/articulos-bg.jpg)">
    <div class="container">
        <div class="row title-row">
            <div class="col-md-8">
                <div class="title-page"><h1> Artículo </h1></div>
            </div>
            <div class="col-md-4">
                <div class="breadcrumbs">
                    <p><a routerLink="/">Inicio &nbsp;/ &nbsp;</a>Artículo</p>
                </div>
            </div>
        </div>
    </div>
</section>
<div class="container pt-5 pb-5">
    <div class="row">
        <div class="col-lg-9 col-md-8 articulo-section">
            <h2 class="titulo-1" innerHtml=" {{ nota?.titulo }}"> </h2>
            <small>
                <span>{{ nota?.autor }}</span><br>
                {{ nota?.fecha | date: 'dd/MM/yyyy' }}
            </small>
            <img class="mt-5 mb-5" src=" {{ nota?.imagen }} " width="100%" alt="prevención del suicidio">
            <div class="row articulo-seccion-contenido">
                <div class="col-12"> 
                    <p innerHtml="{{nota?.contenido}}"></p>
                </div>
            </div>
        </div>
        <!--  ------------------------------------ SIDE MENU -------------------------------------- -->
        <div class="col-lg-3 col-md-4 ">
            <div class="menu-side">

                <div class="titulo-2 pb-1">Compartir esta nota:</div>
                <div class="menu-side-redes">
                    <div [socialShare]="'fb'"> <img src="./assets/images/facebook-logo.png" alt="Redes sociales" class="img-fluid socialMedia"></div>
                    <div [socialShare]="'tw'">  <img src="./assets/images/twitter-logo.png" alt="Redes sociales" class="img-fluid socialMedia"></div>
                </div>
                <div class="titulo-2 pb-1">También te puede Interesar:</div>
                <ul>
                    <li *ngFor="let nota of notas | slice:0:3" style="cursor:pointer"><a
                            (click)="getNotaUrl(nota)">{{ nota?.titulo }}</a>
                    </li>
                </ul>
                <div class="titulo-2 pt-4 pb-4">Ayuda psicológica gratuita profesional</div>
                <div class="menu-side-contacto pb-3">
                    <a href="tel:8009116666"><img src="assets/images/ico-tel-a.svg" alt="icono teléfono"></a>
                    <a><img src="assets/images/ico-chat-a.svg" alt="icono chat"></a>
                    <a href="mailto:centrodiagnostico@simisae.com.mx"><img src="assets/images/ico-mail-a.svg"
                            alt="icono mail"></a>
                </div>
                <div *ngIf="success; else formTemp" class="alert alert-success text-center mt-4">Gracias por enviar tus
                    comentarios</div>
                <ng-template #formTemp>
                    <div class="titulo-2 pt-4">¿Esta nota te fue de ayuda?</div>
                    <form #f="ngForm" ngNativeValidate (ngSubmit)="addComentario(f)" class="form-inline" id="smileys">
                        <div class="form-check col-4">
                            <input type="radio" name="smiley" value="No me gusta" [(ngModel)]="calificacion"
                                class="sad">
                        </div>
                        <div class="form-check col-4">
                            <input type="radio" name="smiley" value="Regular" [(ngModel)]="calificacion"
                                class="neutral">
                        </div>
                        <div class="form-check col-4">
                            <input type="radio" name="smiley" value="Me gusta" [(ngModel)]="calificacion" class="happy">
                        </div>
                        <div class="respuesta"> {{calificacion}} </div>

                        <div *ngIf="calificacion" style="width: 100%;">
                            
                            <div class="form-group">
                                <textarea class="form-control" ngModel name="mensaje" rows="3" required
                                    placeholder="Envíanos tus comentarios"></textarea>
                            </div>
                            <div class="form-group mt-2">
                                <input class="form-control" ngModel  name="email" type="email" id="email" placeholder="E-mail">
                            </div>
                            <div class="form-group mt-2">
                                <div class="form-check check2">
                                    <input ngModel name="acepto" class="form-check-input" type="checkbox" id="checkAcepto"
                                        required>
                                    <label class="form-check-label" for="checkAcepto">
                                        Acepto y estoy de acuerdo con el <a
                                            href="https://www.simisae.com.mx/aviso-legal">aviso legal</a>
                                    </label>
                                </div>
                            </div>
                            <div class="pt-2 text-center">
                                <button class="btn btn-primary" type="submit" [disabled]="loading">
                                    <span *ngIf="!loading; else x">Enviar</span>
                                    <ng-template #x>
                                        <span class="spinner-border spinner-border-sm" role="status"
                                            aria-hidden="true"></span>
                                        Enviando...
                                    </ng-template>
                                </button>
                            </div>
                        </div>
                    </form>
                </ng-template>
            </div>
        </div>
    </div>
</div>
