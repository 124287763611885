import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { SimisaeService } from '../../../services/simisae.service'

@Component({
  selector: 'app-testshistorico',
  templateUrl: './testshistorico.component.html',
  styleUrls: ['./testshistorico.component.css']
})
export class TestshistoricoComponent implements OnInit {
  
  tests!: any[];
  pageActual: number = 1;
  show = false;
  
  constructor(private _simiSAE: SimisaeService,private router: Router) {
    if((sessionStorage.getItem('token') == null || sessionStorage.getItem('token') == 'null')){
      this.router.navigate(['/administrador/login']);
    }
    //this.getTest();
  }

  ngOnInit(): void {
  }
  onSubmit(form: NgForm) {
      
      var Fi = form.value.fechaInicio+" 00:00"
      var FF = form.value.fechafinal+" 23:59"
      this._simiSAE.GetHistorico(sessionStorage.getItem('token'),Fi,FF).subscribe((resp: any) => {
        this.show = true;
        this.tests = resp;
      })
  }
}
