<div class="container">
    <div class="row">
        <div class="col-12 pt-5 pb-5">
            <h1>Aviso de Privacidad</h1><br>
            <!--
            <p>
                1. DATOS PERSONALES O INFORMACIÓN PERSONAL
                <br> 2. DATOS PERSONALES DE MENORES DE EDAD
                <br> 3. FINALIDADES Y/O USOS DE LOS DATOS PERSONALES
                <br> 4. OTRA INFORMACIÓN NO PERSONAL QUE SE RECABA EN NUESTROS SITIOS WEB
                <br> 5. ENLACES DESDE NUESTROS SITIOS WEB
                <br> 6. MEDIDAS DE SEGURIDAD
                <br> 7. TRANSFERENCIAS DE DATOS PERSONALES
                <br> 8. DERECHOS DEL TITULAR DE LOS DATOS PERSONALES
                <br> 9. MODIFICACIONES AL AVISO DE PRIVACIDAD
                <br> 10. CONTACTO
                <br>
                <br> Para <strong>Farmacias de Similares, S.A. de C.V.</strong>, con domicilio en <strong>Alemania
                    número 10, Colonia Independencia, Delegación Benito Juárez, México, Ciudad de México, C.P.
                    03630</strong>&nbsp; (en adelante “SIMILARES”) el tratamiento legítimo, controlado e informado de
                sus datos personales es de vital importancia para alcanzar los objetivos corporativos a través de todas
                las áreas de negocio y reiterar nuestro compromiso con la privacidad y su derecho a la autodeterminación
                informativa.
                <br> El presente Aviso de Privacidad tiene como objeto informarle sobre el tratamiento que se le dará a
                sus datos personales cuando los mismos son recabados, utilizados, almacenados y/o transferidos por
                <strong>“SIMILARES”</strong>, lo cual se lleva a cabo de conformidad con las disposiciones de la Ley
                Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante la “Ley”).
                <br>
                <br>
                <strong>DATOS PERSONALES O INFORMACIÓN PERSONAL </strong>
                <br>
                <br> Para efectos del presente aviso se entiende:</p>
            <br>
            <ul>
                <li>Datos personales: Cualquier información concerniente a una persona física identificada o
                    identificable. Recabamos sus datos personales de forma directa cuando usted mismo nos los
                    proporciona por diversos medios, como cuando participa en nuestras promociones o nos da información
                    con objeto de que le prestemos un servicio o adquiere nuestros productos y/o medicamentos. </li>
                <li>Datos personales sensibles: Aquellos datos personales que afecten a la esfera más íntima de su
                    titular, o cuya utilización indebida pueda dar origen a discriminación o conlleve un riesgo grave
                    para éste. En particular, se consideran sensibles aquellos que puedan revelar aspectos como origen
                    racial o étnico, estado de salud presente y futuro, información genética, creencias religiosas,
                    filosóficas y morales, afiliación sindical, opiniones políticas, preferencia sexual.</li>
            </ul>
            <p>Algunos de los datos personales y/o datos personales sensibles, que nos proporcione, por
                ejemplo, entre otras: cuando se recaban directamente, mediante operaciones y transacciones comerciales,
                correo electrónico, contratos, cartas, solicitudes de información, solicitudes de empleo, solicitudes de
                servicio, distintos formularios contenidos en el Sitio Web de “FARMACIAS SIMILARES”, cuando utiliza
                nuestros servicios en línea, mediante el SIMITEL y/o cualesquiera de nuestras líneas telefónicas,
                registro voluntario en algunos de nuestros programas y/o eventos, &nbsp;bolsa de trabajo, al enviarnos
                un correo electrónico con dudas, comentarios, aclaraciones o quejas y aquellos que se recaben
                necesariamente como consecuencia del reporte de un caso adverso o procedimientos de fármaco vigilancia o
                mediante cualquier otro medio especificado para tales efectos que hagan referencia al presente Aviso de
                Privacidad, pueden ser entre otros: </p>
            <ul>
                <li><strong>Datos de identificación como</strong>: nombre, domicilio, teléfono, CURP, fecha de
                    nacimiento, edad, nacionalidad, imagen, fotografía, país de residencia, número de seguridad social,
                    forma de contacto preferida, correo electrónico,&nbsp; móvil, firmas, huella digital, foto, imagen
                    de la persona a través de video grabación, RFC, documentos de identificación, número de INFONAVIT
                    y/o AFORE, certificado de sello digital y sello digital. </li>
                <li><strong>Datos laborales como</strong>: Puesto, domicilio laboral, teléfono y fax del trabajo,
                    contrato corporativo, nombre del empleador, área o departamento, ocupación, correo electrónico de
                    trabajo, móvil de trabajo, referencias, desempeño, actividades de los últimos 3&nbsp; empleos.</li>
                <li><strong>Datos de educación como</strong>: Trayectoria educativa, título, número de cédula
                    profesional, especialidad, tipos de práctica, certificados de estudios e institución de procedencia.
                </li>
                <li><strong>Datos de salud como:</strong> Estado de salud, historial clínico, enfermedades o
                    padecimientos pasados o presentes, información respecto a los productos de “SIMILARES” que adquiere,
                    fecha de inicio y fin de tratamientos, esquema de administración o uso, tiempo indicado de
                    administración o uso, registro de compras del producto, estudios sobre efectividad del medicamento.
                </li>
                <li><strong>Datos bancarios y de facturación como</strong>: Número de cuenta, CLABE, RFC, datos de
                    facturación, información sobre propiedades, número de tarjeta bancaria, identificadores de cuentas
                    bancarias y/o conceptos de operaciones.</li>
            </ul>
            <p><strong>DATOS PERSONALES DE MENORES DE EDAD</strong><br>
                <br> En “SIMILARES” conocemos la importancia que tiene la protección de la privacidad y los datos
                personales de los menores de edad, por lo que “SIMILARES” no obtiene, usa, divulga o almacena
                información relacionada con menores de edad, sin el consentimiento previo de sus padres o tutores.
                <br> Si usted es padre/madre o tutor y tiene noticia que algún menor de edad haya proporcionado sus
                datos personales sin su consentimiento, puede solicitar que dichos datos sean cancelados contactando a
                “SIMILARES” mediante un aviso por escrito en el domicilio en cita.
                <br><br>
                <strong>FINALIDADES Y/O USOS DE LOS DATOS PERSONALES </strong><br>
                <br> Sus datos personales y/o datos personales sensibles podrán utilizarse para finalidades diversas,
                dependiendo del caso particular en el que sean proporcionados o recabados, siempre acorde con el
                presente Aviso de Privacidad o sus actualizaciones que, en su momento, se pongan a su disposición para
                cumplir con las siguientes finalidades necesarias:</p>
            <ul>
                <li><strong>Si visita nuestras instalaciones para: </strong>controlar el acceso, mantener la
                    confidencialidad de la información propiedad de “SIMILARES”, así como la seguridad dentro de
                    nuestras instalaciones. Las imágenes y sonido captados por las cámaras de videovigilancia serán
                    utilizados para su seguridad mediante la supervisión o monitoreo y de las personas que laboran en
                    nuestras instalaciones o que nos visitan</li>

                <li><strong>Si Usted es un consumidor o posible consumidor para:</strong> cumplir con obligaciones
                    derivadas de una relación jurídica que con Usted se tenga o se llegase a tener, hacer cumplir y/o
                    ejecutar un contrato, &nbsp;cumplir con obligaciones de salud y otras obligaciones legales, fines
                    estadísticos y científicos, intercambiar información científica necesaria para atender un
                    determinado padecimiento o emergencia de salud, hacer reportes a las autoridades sanitarias
                    relativas a posibles experiencias adversas, llevar a cabo inspecciones por parte de las autoridades,
                    auditorías internas, externas y por autoridades, así como para fines comerciales, mercadotécnicos,
                    publicitarios y/o de prospección comercial y para otorgar beneficios y determinar la calidad de
                    nuestros productos y servicios o de otras empresas pertenecientes al mismo grupo
                    corporativo.<strong></strong></li>
                <li><strong>Si es Usted un profesional de la salud para: </strong>cumplir con obligaciones derivadas de
                    una relación jurídica que con Usted se tenga o se llegase a tener, hacer cumplir y/o ejecutar un
                    contrato, fines estadísticos y científicos, intercambiar información científica necesaria para
                    atender un determinado padecimiento o emergencia de salud, hacer reportes a las autoridades
                    sanitarias relativas a posibles experiencias adversas, que se lleven a cabo inspecciones por parte
                    de las autoridades, auditorías internas, externas y por autoridades. Así como para invitarlo a
                    participar en programas, eventos de educación médica, formar una base de datos de profesionales de
                    la salud, contactarlo y hacerle llegar comunicaciones con fines promocionales y educativos,&nbsp;
                    realizar análisis a fin de determinar la eficacia de nuestros programas, envío o entrega de material
                    de apoyo para pacientes, entrega de promociones y premios y registrarlo para recibir información de
                    interés de “SIMILARES” o un tema en particular.</li>

                <li><strong>Si Usted es un proveedor y/o franquicitario para:</strong> registrarlo como proveedor y/o
                    franquiciatario, redactar los instrumentos legales que sean necesarios para sustentar la relación
                    que con Usted se tiene o se quiera tener, realizar pagos, llevar a cabo la facturación<strong>
                    </strong>y para el cumplimiento de las relaciones contractuales con “SIMILARES” y única y
                    exclusivamente podrán ser aplicados o utilizados para el cumplimiento de los fines objeto de dicha
                    relación, ya sea como proveedor y/o franquiciatario.</li>
            </ul>
            <p>Asimismo, sus datos personales podrán tratarse para ciertas finalidades secundarias y
                voluntarias, para las cuales se requerirá su consentimiento previo;<strong></strong></p>
            <ul>
                <li><strong>Si es Usted un consumidor o posible consumidor para:</strong> contactarlo y hacerle llegar
                    comunicaciones con fines promocionales, realizar encuestas, participar en programas de conocimiento
                    de enfermedades, participar en programas de beneficios, programas de lealtad, entrega de premios de
                    lealtad y otras promociones, medir comportamiento del padecimiento y adherencia a tratamientos,
                    desarrollar nuevos productos y servicios, conocer el mercado y el desempeño de la empresa dentro de
                    éste, estudios de mercado, dar seguimiento a las ventas a fin de alcanzar la satisfacción del
                    cliente, servicios de atención al cliente, registrarlo en concursos, eventos, congresos o simposios,
                    presentar un testimonio o anécdota, registrarlo para recibir información de interés de “SIMILARES” o
                    un tema en particular y para Prestación de servicios y cumplimiento de normatividad en materia de
                    Salud. </li>
                <li><strong>Si es Usted un usuario de nuestros sitios web:</strong> para registrarlo en los programas en
                    los que se ha inscrito voluntariamente y enviarle información solicitada o información de
                    “SIMILARES” que consideremos pueda ser de su interés, responder sus dudas o comentarios, creación y
                    administración de su cuenta, cuando decide crearla, registrarlo para recibir información de interés
                    de “SIMILARES” o un tema en particular, </li>
                <li><strong>Si decide contactarnos para enviar su curriculum vitae su información será utilizada
                        para:</strong> contactarlo en caso de que su perfil resulte de interés para “SIMILARES” y
                    participar por una vacante, en ocasiones llevar a cabo evaluaciones para contratación, cuando lo ha
                    autorizado, para llevar a cabo el intercambio de curricula, así como para la negociación, inicio,
                    cumplimiento, mantenimiento y terminación de la relación jurídica entre “SIMILARES” y usted.</li>
            </ul>
            <p>Sus datos personales serán tratados únicamente por el tiempo necesario a fin de cumplir
                con las finalidades descritas en el Aviso de Privacidad que se ponga a su disposición y en su caso
                conforme lo que establecen las disposiciones legales respectivas. <strong></strong>
                <br><br>
                <strong>OTRA INFORMACIÓN NO PERSONAL QUE SE RECABA EN NUESTROS SITIOS WEB </strong>
                <br><br> En algunos casos podemos recibir&nbsp; y almacenar cierta información cuando Usted interactúa
                en
                nuestros sitios web, por ejemplo: información técnica como su dirección de protocolo de internet, su
                sistema operativo y su tipo de navegador, la dirección de un sitio web de referencia y la ruta que usted
                sigue durante su recorrido por nuestros sitios web. Esta información se podrá recaba a fin de permitir
                que los sitios web operen correctamente, evaluar el uso de los sitios web y mostrar información que le
                pudiera resultar de interés.
                <br> Asimismo, &nbsp;pudiéramos utilizar “cookies”, a fin de brindarle una mejor experiencia de
                navegación cuando utilice o regrese a nuestros sitios web. Una “cookie” es un archivo de texto que un
                sitio web o un correo electrónico puede salvar en su navegador y almacenarlo en su disco duro. Usted
                puede elegir navegar en nuestros sitios web sin aceptar las cookies. Sin cookies habilitadas, usted
                podrá navegar en nuestros sitios web, pero es posible que no pueda utilizar algunas aplicaciones o
                características de los sitios web.
                <br><br>
                <strong>ENLACES DESDE NUESTROS SITIOS WEB </strong>
                <br><br> Nuestros sitios web pueden contener, para su conveniencia, enlaces a otros sitios web que no
                pertenecen a “SIMILARES”. “SIMILARES” no ha revisado las Políticas de Privacidad ni Avisos de Privacidad
                de dichos sitios web, por lo que no garantiza ni se hace responsable por el contenido en dichos enlaces
                ni el tratamiento de datos personales que lleven a cabo. Lo exhortamos a que lea cuidadosamente la
                Política y Avisos de Privacidad de cada uno de los sitios que pudieran estar vinculados desde nuestros
                sitios web.
                <br><br>
                <strong>MEDIDAS DE SEGURIDAD </strong><br>
                <br> “SIMILARES” ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y
                físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración,
                destrucción o el uso, acceso o tratamiento no autorizado.
                <br><br>
                <strong>TRANSFERENCIAS DE DATOS PERSONALES </strong>
                <br><br> “SIMILARES” &nbsp;únicamente transferirá sus datos personales a terceros, nacionales o
                extranjeros,
                que estén relacionados jurídica o comercialmente con “SIMILARES” para cumplir con las finalidades
                descritas en el Aviso de Privacidad y en sus actualizaciones puestas a su disposición, por lo que
                “SIMILARES” podrá transferir sus datos personales en los casos previstos y autorizados por la Ley y
                conforme a lo siguiente:</p>
            <ul>
                <li>Al Servicio de Administración Tributaria, en virtud de los procesos de emisión y envío de
                    Comprobantes Fiscales Digitales por Internet.</li>
                <li>A personas afiliadas y/o relacionadas comercialmente con “SIMILARES”;</li>
                <li>A las personas morales o cualquier entidad que formen parte del mismo grupo económico de control al
                    que pertenece “SIMILARES”;</li>
                <li>&nbsp;A compañías afiliadas o no afiliadas que asisten, apoyan o coadyuvan a “SIMILARES”;</li>
                <li>&nbsp;A terceros, para la contratación a nombre de los empleados de “SIMILARES” de todo tipo de
                    seguros y/o servicios a solicitud de los mismos;</li>
                <li>&nbsp;A terceros, para proteger la seguridad y/o intereses de usted, del personal y afiliados de
                    “SIMILARES”, de sus clientes y proveedores o del público en general;</li>
                <li>A distintas autoridades para el desahogo de requerimientos o por constar estos en algún tipo de
                    legislación o normatividad;</li>
            </ul>
            <p><strong>DERECHOS DEL TITULAR DE LOS DATOS PERSONALES </strong><br>
                <br> Usted, puede ejercitar los derechos de acceso, rectificación, cancelación y oposición (derechos
                “ARCO”) respecto a sus datos personales y/o sensibles. Asimismo, Usted puede limitar el uso o
                divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de
                sus datos personales, siempre y cuando el tratamiento no sea un tratamiento necesario o que resulte de
                una relación jurídica.
                <br><br>
                <strong>LOS DERECHOS ARCO CONSISTEN EN:</strong></p>
            <ul>
                <li>Acceso. Que se le informe cuáles de sus datos personales están contenidos en las bases de datos de
                    “SIMILARES”, para qué se utilizan, el origen y las comunicaciones que se hayan realizado con los
                    mismos y, en general, las condiciones y generalidades del tratamiento.</li>
            </ul>
            <p>La obligación de acceso se dará por cumplida cuando: i) los datos se pongan a
                disposición del titular de los datos personales o bien, ii) mediante la expedición de copias simples,
                medios magnéticos, ópticos, sonoros, visuales, holográficos, documentos electrónicos o cualquier otro
                medio o tecnología que se considere adecuada. </p>
            <ul>
                <li>Rectificación. Que se corrijan o actualicen sus datos personales en caso de que sean inexactos o
                    incompletos. </li>
                <li>Cancelación. Que sus datos personales sean dados de baja, total o parcialmente, de las bases de
                    datos de “SIMILARES”. Esta solicitud podrá dar lugar a un período de bloqueo tras el cual procederá
                    la supresión de los datos. </li>
                <li>Oposición. Oponerse por causa legítima al tratamiento de sus datos personales por parte de
                    “SIMILARES”. </li>
            </ul>
            <p>Si desea acceder al contenido de sus datos personales, rectificarlos, cancelarlos u
                oponerse (en lo sucesivo derechos ARCO), hágalo de nuestro conocimiento por medio de un escrito
                presentado en el domicilio en cita indicando:</p>
            <ul>
                <li>Nombre del titular;</li>
                <li>Domicilio de titular o dirección de correo electrónico para comunicar respuesta a solicitud;</li>
                <li>Documentos que acrediten identidad o personalidad para presentar la solicitud;</li>
                <li>Descripción de datos personales sobre los que se pretende ejercer algún derecho ARCO;</li>
                <li>Cualquier otro elemento que permita la localización de los datos personales y atención a la
                    solicitud.</li>
            </ul>
            <p><strong>MODIFICACIONES O ACTUALIZACIONES AL AVISO DE PRIVACIDAD </strong><br>
                <br> “SIMILARES” se reserva el derecho de enmendar, modificar o actualizar el presente Aviso de
                Privacidad o el que haya puesto a su disposición, según sea necesario o conveniente, por ejemplo, para
                cumplir con cambios a la legislación o cumplir con disposiciones internas de “SIMILARES”. “SIMILARES”
                pondrá a su disposición los Avisos de Privacidad actualizados dentro de este sitio web sección aviso de
                privacidad &nbsp;y/o en nuestros puntos de venta.
                <br><br>
                <strong>CONTACTO</strong><br>
                <br> Podrá dirigir preguntas o comentarios respecto al Aviso de Privacidad y/o solicitudes de derechos
                ARCO por medio de un correo electrónico dirigido a la siguiente dirección:
                avisodeprivacidad@fsimilares.com y/o mediante un escrito dirigido a la siguiente dirección: Alemania
                número 10, Colonia Independencia, Delegación Benito Juárez, México, Ciudad de México, C.P. 03630. </p>
                -->

            <p>Para “<strong>Farmacias de Similares”, S.A. de C.V. </strong>con domicilio en <strong>Alemania número 10, Colonia Independencia, Alcaldía Benito Juárez, Ciudad de México, C.P. 03630</strong> (en adelante “<strong>SIMILARES</strong>”) el tratamiento legítimo, controlado e informado de sus datos personales es de vital importancia. Por ello, le reiteramos nuestro compromiso con la privacidad y su derecho a la autodeterminación informativa.</p>
            <p>El presente Aviso de Privacidad tiene como objeto informarle sobre el tratamiento que se les dará a sus datos personales cuando los mismos sean tratados, recabados, utilizados, almacenados y/o transferidos por <strong>“</strong><strong>SIMILARES”</strong>, de conformidad con las disposiciones de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, su Reglamento, Lineamientos y demás disposiciones normativas aplicables y vigentes (en adelante la “Ley”).</p>
            <ol>
                <li><strong>DATOS PERSONALES </strong></li>
            </ol>
            <p>Para las finalidades señaladas en el presente aviso de privacidad, se informa que sus datos personales de identificación son recabados y tratados: i) cuando Usted nos los proporciona directamente y/o ii) a través de la interacción y comunicaciones que tenga con el sitio web: https://simisae.com.mx/</p>
            <p>Para efectos del presente aviso de privacidad se entiende por:</p>
            <p><strong>Datos personales y/o de identificación</strong>: Cualquier información concerniente a una persona física identificada o identificable. <u>Los datos personales que recopilaremos de usted son: nombre, lugar de residencia, correo electrónico, edad</u>, entre otros.</p>
            <p>Además, “<strong>SIMILARES</strong>” recaba y almacena información a través del ingreso a su sitio Web, tal información esta relacionada con la dirección IP/nombre de dominio del visitante, comportamiento y el tiempo de estadía en el sitio Web, herramientas utilizadas, tipo de navegador y sistema operativo entre otros. Dicha información se obtiene y almacena con el fin de medir la actividad del sitio e identificar tendencias sobre navegación que no son atribuibles a un individuo en específico. La información anteriormente señalada se recopila a través de las “cookies”, así como otros medios y mecanismos tecnológicos, tales como las etiquetas de pixel, los web bugs, enlaces en correos electrónicos, web beacons (etiquetas de Internet, etiquetas de pixel y clear GIFs), entre otros. La mayoría de los exploradores le permitirán eliminar el uso de cookies, bloquear la aceptación de las cookies o recibir una advertencia antes de almacenar cookies. Le sugerimos consultar las instrucciones de su explorador para utilizar las “cookies”.</p>
            <ol start="2">
                <li><strong>FINALIDADES Y/O USOS DE LOS DATOS PERSONALES</strong></li>
            </ol>
            <p>Sus datos personales se utilizarán para finalidades que señalan el presente Aviso de Privacidad o sus actualizaciones que, en su momento, se pongan a su disposición y en específico para:</p>
            <ul>
                <li>Identificarlo y permitir su interacción con el Sitio Web.</li>
                <li>Registrarlo para acceder a los “webinars”.</li>
                <li>Contactarlo con motivo de cualquier requerimiento derivado de su asistencia en los “webinars”.</li>
                <li>Responder a sus consultas, dudas y/o aclaraciones.</li>
                <li>Atender sus opiniones, comentarios y/o sugerencias.</li>
                <li>Realizar el envío de información de novedades, noticias, boletines, foros, etc.</li>
            </ul>
            <p>Se hace de su conocimiento que sus datos personales según su propia naturaleza, serán tratados únicamente por el tiempo necesario a fin de cumplir con las finalidades descritas en el Aviso de Privacidad que se ponga a su disposición y en su caso conforme lo que establecen las disposiciones legales respectivas y se conservarán por el tiempo necesario para realizar los fines específicos propios de su naturaleza o en su defecto, hasta en tanto la normatividad aplicable al caso concreto ordene su conservación.</p>
            <ol start="3">
                <li><strong>MEDIDAS DE SEGURIDAD </strong></li>
            </ol>
            <p>“<strong>SIMILARES</strong>” ha implementado y mantiene las medidas de seguridad, técnicas, administrativas y físicas, necesarias para proteger sus datos personales y evitar su daño, pérdida, alteración, destrucción o el uso, acceso o tratamiento no autorizado. En particular, contamos con un Área de Datos Personales, convenios de transferencia, una política de privacidad, capacitación a nuestros empleados, acceso restringido a información personal y a bases de datos que las contienen sólo a usuarios autorizados y cláusulas contractuales.</p>
            <ol start="4">
                <li><strong>TRANSFERENCIAS DE DATOS PERSONALES </strong></li>
            </ol>
            <p>Sus datos personales podrán ser transferidos a las siguientes personas físicas o morales:</p>
            <ul>
                <li>Autoridades a las que debamos hacerlo en virtud de una ley vigente que así lo ordene, con la finalidad de cumplir con la normatividad correspondiente.</li>
                <li>A las personas morales o cualquier entidad que formen parte del mismo grupo económico de control al que pertenece “<strong>SIMILARES</strong>”;</li>
                <li>Para el supuesto de que “<strong>SIMILARES</strong>” estime necesario contratar servicios de almacenamiento de información en la nube con terceros<em>, </em>siempre que estos cumplan con la legislación vigente aplicable y garanticen contar con medidas de seguridad iguales o superiores a las requeridas por la Ley, su reglamento y la autoridad competente</li>
            </ul>
            <ol start="5">
                <li><strong>DERECHOS DEL TITULAR DE LOS DATOS PERSONALES </strong></li>
            </ol>
            <p>Usted puede ejercitar los derechos de acceso, rectificación, cancelación y oposición (derechos “ARCO”) respecto a sus datos personales y/o sensibles y/o patrimoniales y/o financieros. Asimismo, <u>Usted puede limitar el uso o divulgación de sus datos personales y revocar el consentimiento que haya otorgado para el tratamiento de sus datos personales, siempre y cuando el tratamiento no sea un tratamiento necesario o que resulte de una relación jurídica. </u></p>
            
            <ol start="6">
                <li><strong>LOS DERECHOS ARCO CONSISTEN EN:</strong></li>
            </ol>
            <p><strong>Acceso</strong>. Que se le informe cuáles de sus datos personales están contenidos en las bases de datos de “<strong>SIMILARES</strong>”, para qué se utilizan, el origen y las comunicaciones que se hayan realizado con los mismos y, en general, las condiciones y generalidades del tratamiento.</p>
            <p>La obligación de acceso se dará por cumplida cuando: i) los datos se pongan a disposición del titular de los datos personales o bien, ii) mediante la expedición de copias simples, medios magnéticos, ópticos, sonoros, visuales, holográficos, documentos electrónicos o cualquier otro medio o tecnología que se considere adecuada.</p>
            <p><strong>Rectificación</strong>. Que se corrijan o actualicen sus datos personales en caso de que sean inexactos o incompletos.</p>
            <p><strong>Cancelación</strong>. Que sus datos personales sean dados de baja, total o parcialmente, de las bases de datos de “<strong>SIMILARES</strong>”. Esta solicitud podrá dar lugar a un período de bloqueo tras el cual procederá la supresión de los datos.</p>
            <p><strong>Oposición</strong>. Oponerse por causa legítima al tratamiento de sus datos personales por parte de “<strong>SIMILARES</strong>”.</p>
            <ol start="7">
                <li><strong>EJERCICIO DE LOS DERECHOS ARCO</strong></li>
            </ol>
            <p>Si desea <u>acceder al contenido de sus datos personales, rectificarlos, cancelarlos u oponerse y/o revocar su consentimiento</u>, hágalo de nuestro conocimiento por medio de un escrito libre presentado en el domicilio en cita o mediante un correo electrónico a la dirección avisodeprivacidad@fsimilares.com, indicando:</p>
            <ol>
                <li>Nombre del titular;</li>
                <li>Domicilio de titular o dirección de correo electrónico para comunicar respuesta a solicitud;</li>
                <li>Documentos que acrediten identidad o personalidad para presentar la solicitud;</li>
                <li>Descripción de datos personales sobre los que se pretende ejercer algún derecho ARCO;</li>
                <li>Cualquier otro elemento que permita la localización de los datos personales y atención a la solicitud.</li>
            </ol>
            <p>Se informa que su solicitud, los plazos para el ejercicio de los derechos ARCO, así como del derecho de revocación, se regirán por lo dispuesto en la Ley, en caso de tener alguna duda o consulta, le agradeceremos se ponga en contacto a nuestro correo de privacidad <a href="mailto:avisodeprivacidad@fsimilares.com">avisodeprivacidad@fsimilares.com</a></p>
            <p>Nuestra Área de Datos Personales velará en todo momento por la protección de su información personal en cumplimiento con la normatividad aplicable quien dará trámite a su solicitud y atenderá cualquier comentario, duda o queja que pudiera tener respecto al tratamiento de su información personal.</p>
            <p>Asimismo, le informamos que el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales es la autoridad encargada de vigilar por la debida observancia de las disposiciones legales en materia de protección de datos personales.</p>
            <ol start="8">
                <li><strong>REVOCACIÓN DE DERECHOS ARCO</strong></li>
            </ol>
            <p>Asimismo, Usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales. Sin embargo, es importante que tenga en cuenta que no en todos los casos podremos concluir el uso de forma inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará la conclusión de su relación con nosotros.</p>
            <ol start="9">
                <li><strong>MODIFICACIONES O ACTUALIZACIONES AL AVISO DE PRIVACIDAD </strong></li>
            </ol>
            <p>“<strong>SIMILARES</strong>” se reserva el derecho de enmendar, modificar o actualizar el presente Aviso de Privacidad o el que haya puesto a su disposición, según sea necesario o conveniente, por ejemplo, para cumplir con cambios a la legislación o cumplir con disposiciones internas de “<strong>SIMILARES</strong>”. “<strong>SIMILARES</strong>” pondrá a su disposición los Avisos de Privacidad actualizado de manera personal.</p>
            <ol start="10">
                <li><strong>CONTACTO</strong></li>
            </ol>
            <p>Podrá dirigir preguntas o comentarios respecto al Aviso de Privacidad y/o solicitudes de derechos ARCO por medio de un correo electrónico dirigido a la siguiente dirección:_ avisodeprivacidad@fsimilares.com y/o mediante un escrito dirigido a la siguiente dirección: Alemania número 10, Colonia Independencia, Alcaldía Benito Juárez, Ciudad de México, C.P. 03630.</p>
            <p><strong>Mediante el uso, visita y/o su registro como usuario en el sitio web y/o la App, Usted manifiesta su aceptación con el presente Aviso de Privacidad y su autorización para la transferencia de sus datos personales en los términos indicados en el presente, de conformidad con lo dispuesto por la fracción I del artículo 1803 del Código Civil Federal.</strong></p>    
        </div>
    </div>
</div>